import React, { useEffect, useRef } from 'react';
import logo from './images/logo.png';
import logoblue from './images/logoblue.png';
import banner1 from './images/banner1.png'
import doctor from './images/doctor.png'
import doctor1 from './images/doctor1.png'
import Epilepsy from './images/Epilepsy.jpeg';
import MultipleSclerosis from './images/MultipleSclerosis.jpeg';
import DemyelinatingDisorders from './images/Demyelinating Disorders.jpeg';
import Stroke from './images/Stroke.jpeg';
import ConginitiveNeurology from './images/Conginitive Neurology.jpeg';
import back from './images/back.png';
import Wavify from 'react-wavify';
function Home() {

    return (
        <div >

            <nav className="navbar navbar-expand-lg fixed-top custom-nav sticky">
                <div className="container">
                    <a className="navbar-brand brand-logo mr-4" href="#home">
                        <img src={logo} className="img-fluid logo-light" alt="" width={250} />
                        <img src={logoblue} className="img-fluid logo-dark" id='bluelogo' alt="" width={200} />
                    </a>
                    <div className="navbar-collapse collapse justify-content-center" id="navbarCollapse">
                        <ul className="navbar-nav navbar-center" id="mySidenav">
                            <li className="nav-item active">
                                <a href="#home" className="nav-link">Home</a>
                            </li>
                            <li className="nav-item">
                                <a href="#features" className="nav-link">Services</a>
                            </li>
                            <li className="nav-item">
                                <a href="#aboutus" className="nav-link">About Us</a>
                            </li>
                            <li class="nav-item">

                                <a href="#departments" class="nav-link">Disorders</a>

                            </li>
                            <li className="nav-item">
                                <a href="#premises" className="nav-link">Gallery</a>
                            </li>
                            <li className="nav-item">
                                <a href="#faq" className="nav-link">FAQ's</a>
                            </li>
                            
                        </ul>

                    </div>

                    <div class="contact_btn">
                        <a href="https://web.whatsapp.com/send?phone=918985865555&amp;text=" class="btn btn-sm" target='blank'>APPOINTMENT</a>

                    </div>

                </div>
                <div class="contact_btn1">
                 
                    <a href="tel:+ 91 898 586 55 55" class="btn btn-sm" style={{marginRight:'50px'}}><i className="icofont-phone" />+91 898 586 55 55</a>
                </div>
            </nav>
            <section className="bg-gradient overflow-hidden home-section" id="home">
                <div className="waves-bg home-bg">
                    <div className="container">
                        <div className="owl-carousel owl-theme home-slider">
                            <div className="item">
                                <div className="row align-items-center">
                                <div className="col-md-6">
                                        <div className="img-fadeInRight">
                                            <img src={doctor} className="img-fluid" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="content-fadeInUp">
                                            <h2 className="heading">
                                                Welcome to saiNeuro
                                            </h2>
                                            <h1 className="text-white sub-title">Best Care &amp; Better Doctor</h1>
                                            <p className="para-sec">
                                            Our specialist, Dr Eeswar D, has years of experience in neurology from Gandhi Hospital.
                                            </p>
                                            <div className="learn-more">
                                                <a href="#aboutus" className="btn btn-white btn-rounded text-white">Learn More</a>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className="item">
                                <div className="row align-items-center">
                                    <div className="col-md-7">
                                        <div className="content-fadeInUp">
                                            <h2 className="heading">
                                                SaiRamNeuro specializes in brain and spinal issues
                                            </h2>
                                            {/* <h1 className="text-white sub-title">Best Care &amp; Better Doctor</h1> */}
                                            <p className="para-sec">
                                        
                                                Better health care with efficient cost is the main focuse of our hospital.
                                            </p>
                                            <div className="learn-more">
                                                <a href="#aboutus" className="btn btn-white btn-rounded text-white">Learn More</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="img-fadeInRight">
                                            <img src={doctor1} className="img-fluid" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div class="hero-waves">

                        <svg style={{width:'100%',height:'100%'}}  version="1.1" xmlns="http://www.w3.org/2000/svg" class="wave">

                            <defs></defs>

                            <path id="wave1" d="M 0 59.01634660349911 C 177.875 37.04513628513232 177.875 37.04513628513232 355.75 48.03074144431571 C 533.625 59.01634660349911 533.625 59.01634660349911 711.5 41.226342656363634 C 889.375 23.436338709228153 889.375 23.436338709228153 1067.25 40.98012250005686 C 1245.125 58.52390629088556 1245.125 58.52390629088556 1423 41.226342656363634 L 1423 6836.578125 L 0 6836.578125 Z" fill="#fff"></path>

                        </svg>

                        <svg style={{width:'100%',height:'100%'}} version="1.1" xmlns="http://www.w3.org/2000/svg" class="wave">

                            <defs></defs>

                            <path id="wave2" d="M 0 35.32870989727976 C 237.16666666666666 66.04326639487081 237.16666666666666 66.04326639487081 474.3333333333333 50.68598814607527 C 711.5 35.32870989727976 711.5 35.32870989727976 948.6666666666666 56.21861817432841 C 1185.8333333333333 77.10852645137706 1185.8333333333333 77.10852645137706 1423 55.328817759578826 L 1423 6836.578125 L 0 6836.578125 Z" fill="rgba(255, 255, 255, .1)"></path>

                        </svg> */}

                    <div class="hero-waves">


                        <Wavify

                            options={{
                                height: 40,
                                bones: 4,
                                amplitude: 50,
                                color: '#fff',
                                speed: 0.15,
                                horizontal: true // Set to true to make the wave move horizontally
                            }}
                        />
                        <Wavify

                            options={{
                                height: 20,
                                bones: 3,
                                amplitude: 50,
                                color: 'rgba(255, 255, 255, .1)',
                                speed: 0.25,
                                horizontal: true // Set to true to make the wave move horizontally
                            }}
                        />
                    </div>



                </div>
            </section>

            <section id="features" className="section features">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="section-title text-center mb-4">
                                <h2>Our Services</h2>
                                <p className="section-subtitle mx-auto">Neurology is a medical specialty dealing with disorders of the nervous system. To be specific, it deals with the diagnosis and treatment of all categories of diseases involving the central, peripheral and autonomic nervous systems including their coverings, blood vessels and all effectors such as muscle tissues.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="ftr-icon-box" data-wow-delay="0.3ms"
                                data-wow-duration="2500ms"
                                data-aos="fade-up" >
                                <div className="icon"><i className="icofont-doctor" /></div>
                                <h4 className="title">ALZHEIMERS DISEASES</h4>
                                <p className="description">Alzheimers is a progressive brain disorder affecting memory, thinking and behavior. People aged 65 or above are most commonly affected with Alzheimer’s. </p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="ftr-icon-box"
                                data-wow-delay="0.2ms"
                                data-wow-duration="2500ms"
                                data-aos="fade-up"
                            >
                                <div className="icon"><i className="icofont-stretcher" /></div>
                                <h4 className="title">BACK PAIN</h4>
                                <p className="description">Back pain can be pain in middle back or lower back. Middle back is area below neck till the bottom of rib cage while lower back is lower part of spine. </p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="ftr-icon-box " data-wow-delay="0.6s"
                                data-wow-duration="2500ms"
                                data-aos="fade-up">
                                <div className="icon"><i class="icofont-brain-alt"></i></div>
                                <h4 className="title">BRAIN HAEMORRHAGE </h4>
                                <p className="description">Caused by rupture of arteries in the brain resulting in bleeding. When enough bleeding occurs, blood occupies space and compresses surrounding brain tissue .</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="ftr-icon-box " data-wow-delay="0.6s"
                                data-wow-duration="2500ms"
                                data-aos="fade-up">
                                <div className="icon"><i class="icofont-user-alt-2"></i></div>
                                <h4 className="title">HEADACHE </h4>
                                <p className="description">Headache is pain in any region of head or neck including nerves, blood vessels, and muscles that cover them. Headaches can be caused due to variety of underlying factors.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="ftr-icon-box " data-wow-delay="0.6s"
                                data-wow-duration="2500ms"
                                data-aos="fade-up">
                                <div className="icon"><i class="icofont-brand-slideshare"></i></div>
                                <h4 className="title">SLEEP DISORDERS </h4>
                                <p className="description">The inability to sleep well or change in sleeping patterns affect our mental thinking and health. Any such thing that disturbs or disrupts our sleep quality is called sleep disorder.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="ftr-icon-box " data-wow-delay="0.6s"
                                data-wow-duration="2500ms"
                                data-aos="fade-up">
                                <div className="icon"><i class="icofont-brainstorming"></i></div>
                                <h4 className="title">STROKES </h4>
                                <p className="description">Stroke is a condition which occurs when blood flow to the brain is interrupted due to formation of clot in the artery. It can affect both physically and mentally </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="aboutus" className="aboutus section inverse-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7">
                            <div className="mb-30">
                                <div className="section-title mb-4">
                                    <h4 className="sub-title">About us</h4>
                                    <h2>Neurology Emergency</h2>
                                    <p>Stroke - within 3-4 hours of stroke onset - patient will be eligible for thrombolysis therapy; other stroke with onset less than a week will be seen and evaluated in emergency. Strokes which have occurred earlie than a week will be evaluated only in the OPD.</p>
                                </div>
                                <div className="icon-boxes">
                                    <div className="aboutus-icon-box d-flex align-items-start">
                                        <div className="icon"><i class="icofont-stretcher"></i></div>
                                        <div className="ml-4">
                                            <h4 className="title">Seizures or 'Fits'</h4>
                                            <p className="description">that have occurred in the previous 24 hours</p>
                                        </div>
                                    </div>
                                    <div className="aboutus-icon-box d-flex align-items-start">
                                        <div className="icon"><i class="icofont-stethoscope-alt"></i></div>
                                        <div className="ml-4">
                                            <h4 className="title">Excessive drowsiness or Unconsciousness</h4>
                                            <p className="description">Patients who are sleeping excessively and non-responsiv spoken words</p>
                                        </div>
                                    </div>
                                    <div className="aboutus-icon-box d-flex align-items-start">
                                        <div className="icon"><i class="icofont-tablets"></i></div>
                                        <div className="ml-4">
                                            <h4 className="title">Severe Headache</h4>
                                            <p className="description">Severe headache with or without fever which may suggest a brain infection, meningitis or brain haemorrhage or even brain tumour</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <img src="images/aboutus/about-doc-img.jpg" alt="" className="w-100 mb-30" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="section" id="departments">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="section-title text-center mb-4">
                                <h2>Our Disorders</h2>
                                <p className="section-subtitle mx-auto">A Neurological disorder is any disorder of the body nervous system affecting the brain, spinal cord or other nerves. Examples of symptoms include paralysis, muscle weakness, poor coordination, loss of sensation, seizures (epilepsy, fits), confusion, headache, pain and altered levels of consciousness.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="dept-box">
                                <div>
                                    <img src={Epilepsy} alt="" className="w-100" />
                                </div>
                                <div className="dept-details">
                                    <div className="round-style" />
                                    <i class="icon icofont-brain"></i>
                                    <h4>Epilepsy</h4>

                                    <p className="details">Childhood epilepsies</p>
                                    <p className="details">Epilepsy & pregnancy</p>
                                    <p className="details">Generalized epilepsies</p>
                                    <p className="details">Focal epilepsies & auras</p>

                                    <a href="#footer" className="thm-btn"><i className="icofont-envelope" /> Contact</a>
                                    <a className="thm-btn inverse" href="#"><i className="icofont-heart" /> Support</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="dept-box">
                                <div>
                                    <img src={MultipleSclerosis} alt="" className="w-100" />
                                </div>
                                <div className="dept-details">
                                    <div className="round-style" />
                                    <i className="icon icofont-autism" />
                                    <h4>Multiple Sclerosis</h4>
                                    <p className="details"> SPMS</p>
                                    <p className="details"> Clinically Isolated Syndrome</p>
                                    <p className="details"> RRMS</p>

                                    <p className="details"> Relapses</p>
                                    <a href="#footer" className="thm-btn"><i className="icofont-envelope" /> Contact</a>
                                    <a className="thm-btn inverse" href="#"><i className="icofont-heart" /> Support</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="dept-box">
                                <div>
                                    <img src={DemyelinatingDisorders} alt="" className="w-100" />
                                </div>
                                <div className="dept-details">
                                    <div className="round-style" />
                                    <i className="icon icofont-crutch" />
                                    <h4>Demyelinating Disorders</h4>

                                    <p className="details">NMO-spectrum disorder</p>
                                    <p className="details">ADEM</p>
                                    <p className="details">MOG antibody disease</p>

                                    <p className="details">Tumefactive demyelination</p>

                                    <a href="#" className="thm-btn"><i className="icofont-envelope" /> Contact</a>
                                    <a className="thm-btn inverse" href="#"><i className="icofont-heart" /> Support</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="dept-box">
                                <div>
                                    <img src={Stroke} alt="" className="w-100" />
                                </div>
                                <div className="dept-details">
                                    <div className="round-style" />
                                    <i className="icon icofont-surgeon" />
                                    <h4>Stroke</h4>
                                    <p className="details">Brain Hemorrhage</p>
                                    <p className="details">Ischemic Stroke</p>
                                    <p className="details">Transient Ischemic Attack</p>

                                    <p className="details">Carotid endarterectomy & stenting</p>
                                    <a href="#footer" className="thm-btn"><i className="icofont-envelope" /> Contact</a>
                                    <a className="thm-btn inverse" href="#"><i className="icofont-heart" /> Support</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="dept-box">
                                <div>
                                    <img src={ConginitiveNeurology} alt="" className="w-100" />
                                </div>
                                <div className="dept-details">
                                    <div className="round-style" />
                                    <i class="icon icofont-memorial"></i>
                                    <h4>Cognitive Neurology</h4>
                                    <p className="details">  Dementia</p>
                                    <p className="details"> Memory loss</p>

                                    <p className="details"> Cognitive Training</p>
                                    <p className="details">  Mild Cognitive Impairment</p>

                                    <a href="#" className="thm-btn"><i className="icofont-envelope" /> Contact</a>
                                    <a className="thm-btn inverse" href="#"><i className="icofont-heart" /> Support</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="dept-box">
                                <div>
                                    <img src={back} alt="" className="w-100" />
                                </div>
                                <div className="dept-details">
                                    <div className="round-style" />
                                    <i class="icon icofont-bone"></i>
                                    <h4>Neck & Back Pain</h4>
                                    <p className="details">Cervical spondylosis </p>

                                    <p className="details">Fibromyalgia</p>
                                    <p className="details">Low back ache</p>
                                    <p className="details">Sciatica </p>
                                    <a href="#" className="thm-btn"><i className="icofont-envelope" /> Contact</a>
                                    <a className="thm-btn inverse" href="#"><i className="icofont-heart" /> Support</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="dept-box">
                                <div>
                                    <img src={Epilepsy} alt="" className="w-100" />
                                </div>
                                <div className="dept-details">
                                    <div className="round-style" />
                                    <i class="icon icofont-bone"></i>
                                    <h4>Cognitive Neurology</h4>
                                    <p className="details">Memory loss </p>

                                    <p className="details">Mild Cognitive impairment </p>
                                    <p className="details">Dementia</p>
                                    <p className="details"> Cognitive Training </p>
                                    <a href="#" className="thm-btn"><i className="icofont-envelope" /> Contact</a>
                                    <a className="thm-btn inverse" href="#"><i className="icofont-heart" /> Support</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="dept-box">
                                <div>
                                    <img src={back} alt="" className="w-100" />
                                </div>
                                <div className="dept-details">
                                    <div className="round-style" />
                                    <i class="icon icofont-bone"></i>
                                    <h4>Headache & facial pain</h4>
                                    <p className="details"> Migraine </p>

                                    <p className="details">Tension-Type headache </p>
                                    <p className="details">Cluster headache</p>
                                    <p className="details">Intracranial hypertension </p>
                                    <a href="#" className="thm-btn"><i className="icofont-envelope" /> Contact</a>
                                    <a className="thm-btn inverse" href="#"><i className="icofont-heart" /> Support</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="dept-box">
                                <div>
                                    <img src={MultipleSclerosis} alt="" className="w-100" />
                                </div>
                                <div className="dept-details">
                                    <div className="round-style" />
                                    <i class="icon icofont-bone"></i>
                                    <h4>Neuroimmune disorders</h4>
                                    <p className="details">Myasthenia Gravis </p>

                                    <p className="details">Auto immune encephalitis CIDP</p>
                                    <p className="details">Primary CNS angitis</p>

                                    <a href="#" className="thm-btn"><i className="icofont-envelope" /> Contact</a>
                                    <a className="thm-btn inverse" href="#"><i className="icofont-heart" /> Support</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="dept-box">
                                <div>
                                    <img src={DemyelinatingDisorders} alt="" className="w-100" />
                                </div>
                                <div className="dept-details">
                                    <div className="round-style" />
                                    <i class="icon icofont-bone"></i>
                                    <h4>Unconsciousness</h4>
                                    <p className="details">Syncope Seizure </p>

                                    <p className="details">Stroke</p>
                                    <p className="details">Falls</p>

                                    <a href="#" className="thm-btn"><i className="icofont-envelope" /> Contact</a>
                                    <a className="thm-btn inverse" href="#"><i className="icofont-heart" /> Support</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="dept-box">
                                <div>
                                    <img src={Stroke} alt="" className="w-100" />
                                </div>
                                <div className="dept-details">
                                    <div className="round-style" />
                                    <i class="icon icofont-bone"></i>
                                    <h4>Neuropsychiatry</h4>
                                    <p className="details">Hallucinations </p>

                                    <p className="details">Acute confusional state </p>
                                    <p className="details">ADHD</p>
                                    <p className="details">Autism Spectrum Disorder </p>
                                    <a href="#" className="thm-btn"><i className="icofont-envelope" /> Contact</a>
                                    <a className="thm-btn inverse" href="#"><i className="icofont-heart" /> Support</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="dept-box">
                                <div>
                                    <img src={ConginitiveNeurology} alt="" className="w-100" />
                                </div>
                                <div className="dept-details">
                                    <div className="round-style" />
                                    <i class="icon icofont-bone"></i>
                                    <h4>Giddiness & Vertigo</h4>
                                    <p className="details">BPPV </p>

                                    <p className="details">Vertigenous Migraine </p>
                                    <p className="details">Imbalance(Tinnitus ringing ears)</p>

                                    <a href="#" className="thm-btn"><i className="icofont-envelope" /> Contact</a>
                                    <a className="thm-btn inverse" href="#"><i className="icofont-heart" /> Support</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="dept-box">
                                <div>
                                    <img src={Epilepsy} alt="" className="w-100" />
                                </div>
                                <div className="dept-details">
                                    <div className="round-style" />
                                    <i class="icon icofont-bone"></i>
                                    <h4>Leg and Arm pain</h4>
                                    <p className="details">Cervical radiculopathy  </p>

                                    <p className="details">Muscle cramps </p>
                                    <p className="details">Restless leg syndrome</p>
                                    <p className="details">Cervical radiculopathy</p>
                                    <p className="details">Autism Spectrum Disorder </p>
                                    <a href="#" className="thm-btn"><i className="icofont-envelope" /> Contact</a>
                                    <a className="thm-btn inverse" href="#"><i className="icofont-heart" /> Support</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section bg-gradient" id="reviews">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="section-title text-center mb-4">
                                <h2 className="text-white">Patient Reviews</h2>
                                <p className="text-white section-subtitle mx-auto">There are many variations of Patient Reviews & FeedBack about SaiRamNeuro.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="owl-carousel owl-theme review-slider">

                                <div className="item">
                                    <div className="review-card shadow-md">
                                        <p>SAI RAM NEURO SUPER SPECIALITY HOSPITAL provides excellent treatment. The staff is very caring and professional. and the traitment also so good ,very comfortuble to talk with doctor so that  I am very satisfied with the care I received.</p>
                                    </div>
                                    {/* <div className="user-txt">
                                        <div className="user-pics">
                                            <img src="images/reviews/feedback-2.jpg" alt="" />
                                            </div>
                                        <div className="user-info">
                                            <h6 className="heading text-white">David</h6>
                                            <p className="sub-heading text-white">SairamNuro User</p>
                                        </div>
                                    </div> */}
                                </div>
                                <div className="item">
                                    <div className="review-card shadow-md">
                                        <p>SAI RAM NEURO SUPER SPECIALITY HOSPITAL provides excellent care at a reasonably priced. The staff is compassionate and the facilities are top-notch. I highly recommend and suggested this hospital for saiNeuro-related treatments.</p>
                                    </div>
                                    {/* <div className="user-txt">
                                        <div className="user-pics"><img src="images/reviews/feedback-3.jpg" alt="" /></div>
                                        <div className="user-info">
                                            <h6 className="heading text-white">Ramu</h6>
                                            <p className="sub-heading text-white">SairamNuro User</p>
                                        </div>
                                    </div> */}
                                </div>
                                <div className="item">
                                    <div className="review-card shadow-md">
                                        <p>I had an excellent experience at SAI RAM NEURO SUPER SPECIALITY HOSPITAL. The facilities were top-notch and the customer service was great. I visited for a routine check-up and was impressed by the professionalism of the staff.Overall.</p>
                                    </div>
                                    {/* <div className="user-txt">
                                        <div className="user-pics"><img src="images/reviews/feedback-4.jpg" alt="" /></div>
                                        <div className="user-info">
                                            <h6 className="heading text-white">Hanuman</h6>
                                            <p className="sub-heading text-white">SairamNuro User</p>
                                        </div>
                                    </div> */}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section inverse-bg" id="premises">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="section-title text-center mb-4">
                                <h2>Our Gallery</h2>
                                <p className="section-subtitle mx-auto">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="owl-carousel owl-theme premises-slider">
                                <div className="item">
                                    <div className="premises-box">
                                        <img src="images/premises/01.jpg" alt="" />
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="premises-box">
                                        <img src="images/premises/02.jpg" alt="" />
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="premises-box">
                                        <img src="images/premises/03.jpg" alt="" />
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="premises-box">
                                        <img src="images/premises/04.jpg" alt="" />
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="premises-box">
                                        <img src="images/premises/05.jpg" alt="" />
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="premises-box">
                                        <img src="images/premises/06.jpg" alt="" />
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="premises-box">
                                        <img src="images/premises/07.jpg" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="faq" className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="section-title text-center mb-4">
                                <h2>Frequently Asked</h2>

                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-md-7">
                            <div id="accordion" className="faq-panel wow fadeInUp" data-wow-duration="1500ms">
                                <div className="question-box shadow-sm active">
                                    <div className="faq-header p-0" id="headingOne">
                                        <h4 className="mb-0">
                                            <button className="btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne"><span>1. Physiotherapy & Neuro-Rehabilitation?</span><span><i className="icofont-rounded-down caret-icon" /></span></button>
                                        </h4>
                                    </div>
                                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                                        <div className="faq-body">
                                            <p>
                                                Many neurological conditions get better with physio and rehabilitation. Our services are provided by dedicated physiotherapy and rehabilitation experts. Our team of skilled therapists work closely with patients and neurologists to develop customized treatment plans aimed at improving mobility, function, and quality of life. Through a combination of exercises, therapeutic techniques, and assistive devices, our physio and rehab services help
                                                patients regain independence and enhance their overall well-being.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="question-box shadow-sm">
                                    <div className="faq-header p-0" id="headingTwo">
                                        <h4 className="mb-0">
                                            <button className="btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"><span>
                                                2. Electro Diagnostics: EEG/EMG/VEEG/Sleep Studies?</span><span><i className="icofont-rounded-down caret-icon" /></span></button>
                                        </h4>
                                    </div>
                                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                        <div className="faq-body">
                                            <p>
                                                Electroencephalography
                                                (EEG) records the electrical activity of the brain and is
                                                done in patients with
                                                epilepsy and in some
                                                instances of altered
                                                consciousness,
                                                encephalopathy,
                                                dementia, coma, etc. It is important to record the EEG with standardized protocols in good quality EEG machines capable of high sampling rates with electrodes placed after measuring the correct points on the scalp using a 10-20 system. For
                                                maximum yields, we ask the patient to come with deprived sleep and the recording is done with the patient awake as well as asleep. Depending on the clinical suspicion, specific activation procedures are carried out during the recording. The recording is interpreted by qualified Epileptologists who have a vast experience in EEG and
                                                managing epilepsy.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="question-box shadow-sm">
                                    <div className="faq-header p-0" id="headingThree">
                                        <h4 className="mb-0">
                                            <button className="btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree"><span>
                                                3. Electro Neuro Myography (ENMG)/Nerve
                                                Conduction Studies (NCS)?</span><span><i className="icofont-rounded-down caret-icon" /></span></button>
                                        </h4>
                                    </div>
                                    <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                                        <div className="faq-body">
                                            <p>
                                                These are electrical tests done to diagnose
                                                disorders of peripheral nerves and muscles. The tests are standardized but are tailored as per each patient's clinical situation for obtaining optimal information.
                                                Visual Evoked Potential (VEP): The VEP assess optic nerve functions and is useful in conditions like optic neuritis. The test is performed using
                                                standardized protocols to ensure reproducibility and future comparisons.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="wow fadeIn" data-wow-duration="1500ms">
                                <img src="images/faqs.png" className="w-100" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <a href="https://web.whatsapp.com/send?phone=918985865555&amp;text=" target="_blank" class="back_top" tooltip="WhatsApp">
                <i class="icofont-whatsapp"></i>
            </a>
            <footer className="footer bg-gradient overflow-hidden pb-4" id="footer">
                <div className="container footer-top mb-4">
                </div>
                <div className="container footer-bottom">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="foot-logo">
                                <img src="images/logo.png" className="mt-2" alt="" />
                            </div>
                            <p className="mt-4 text-left ftr-about">Our SaiRamNeuro services are provided by dedicated physiotherapy and rehabilitation experts. Our team of skilled therapists work closely with patients and neurologists to develop customized treatment plans aimed at improving of life.</p>
                            <div className="mt-4">
                                <ul className="footer-social list-inline mt-4">
                                    <li className="list-inline-item"><a href="https://www.facebook.com/sairamneuro" className="social-icon" target='blank'><i className="icofont-facebook" /></a></li>

                                    <li className="list-inline-item"><a href="https://www.instagram.com/sairamneuro/" className="social-icon" target='blank'><i className="icofont-instagram" /></a></li>

                                </ul>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-4">
                            <h5 className="footer-title">Quick Links</h5>
                            <ul className="footer-menu list-unstyled mb-0 mt-4">
                                <li><a href="#home">Home</a></li>
                                <li><a href="#features">Services</a></li>
                                <li><a href="#aboutus">About Us</a></li>
                                {/* <li><a href="#">Departments</a></li> */}
                                {/* <li><a href="#">Our Team</a></li> */}
                            </ul>
                        </div>
                        <div className="col-md-2 col-sm-4">
                            <h5 className="footer-title">Useful Links</h5>
                            <ul className="footer-menu list-unstyled mb-0 mt-4">
                                <li><a href="#premises">Gallery</a></li>
                                <li><a href="#faq">FAQ's</a></li>
                                <li><a href="https://web.whatsapp.com/send?phone=918985865555&amp;text=" target='blank'>Appointment</a></li>
                            </ul>
                        </div>
                        <div className="col-md-4 col-sm-4">
                            <h5 className="footer-title">Quick Contact</h5>
                            <ul className="footer-menu list-unstyled mb-0 mt-4 contact-menu-list">
                                <li>
                                    <i className="icofont-location-pin" />
                                    <a href="https://www.google.com/maps/search/Opposite+Vishal+Mart,+Dvk+Road,+Nalgonda+Main+Road,+Nalgonda+-+508001+(Nalgonda)/@17.0518386,79.2583679,15z/data=!3m1!4b1?entry=ttu" target="_blank">

                                        Location: Opposite Vishal Mart, Dvk Road, Nalgonda Main Road, Nalgonda - 508001 (Nalgonda)
                                    </a>
                                </li>
                                <li><i className="icofont-envelope" /><a href="mailto: nalgondaneuro@gmail.com ">  nalgondaneuro@gmail.com </a></li>
                                <li><i className="icofont-phone" /><a href="tel:+ 91 8985865555">+ 91 8985865555</a></li>
                            </ul>
                        </div>
                        <div className="col-md-12">
                            <div className="footer-desc mt-4 pt-4">
                                <span className='botm'> Copyright © 2024 SaiRamNeuro All Rights Reserved -Designed by <a href="https://digitalraiz.com/" style={{ color: '#ed7837' }} target='blank'> DigitalRaiz</a></span>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

        </div>
    )
}

export default Home